* {
  margin: 0;
  box-sizing: border-box;
  font-weight: 300;
  transition: all linear 200ms;
  -ms-overflow-style: none /* IE and Edge */;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
:root {
  --lightgray1: #e5e5e5;
  --lightgray2: #c4c4c4;
  --green: #ddffc9;
  background-color: black;
}
img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
p,
button {
  font-family: 'Neue';
}
* {
  font-size: 18px;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 24px;
}
button {
  font-size: 24px;
}

.rotate90 {
  transform: rotate(90deg) !important;
}

.rotate180 {
  transform: rotate(180deg) !important;
}
.rotate270 {
  transform: rotate(270deg) !important;
}
.gray {
  color: gray;
}

/* HEADER */
.page {
  padding-left: 70px;
  transition: all linear 200ms;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100vw;
  min-height: 100vh;
}
.xlr:hover {
  color: black !important;
  background-color: var(--green);
}
.xlr2:hover {
  color: var(--green) !important;
  background-color: black;
}
._xlr:hover,
._xlr2:hover {
  /* color: #bbb !important; */
  font-family: 'NeueItalic';
}

/* HOME */
.home {
  object-fit: cover;
  width: calc(100vw - 70px);
  height: 100vh;
  position: absolute;
  top: 0;
}
.home_slider {
  height: 100vh;
}
.home_slider * {
  height: 100vh;
}

/* GALLERY */
.gallery {
  position: relative;
  top: 0;
  left: 0;
  min-width: calc(100vw - 70px);
  min-height: 100vh;
  display: block;
}

/* PROJECTS */

@media all and (max-width: 800px) {
  .home {
    width: 100vw;
  }
}
