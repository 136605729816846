/* @font-face {
  font-family: "Sans60";
  src: url("./authen/AUTHENTICSans-60.woff");
}
@font-face {
  font-family: "Sans90";
  src: url("./authen/AUTHENTICSans-90.woff");
}
@font-face {
  font-family: "Sans130";
  src: url("./authen/AUTHENTICSans-130.woff");
}
@font-face {
  font-family: "Sans150";
  src: url("./authen/AUTHENTICSans-150.woff");
}
@font-face {
  font-family: "SansCondensed60";
  src: url("./authen/AUTHENTICSans-Condensed-60.woff");
}
@font-face {
  font-family: "SansCondensed90";
  src: url("./authen/AUTHENTICSans-Condensed-90.woff");
}
@font-face {
  font-family: "SansCondensed130";
  src: url("./authen/AUTHENTICSans-Condensed-130.woff");
}
@font-face {
  font-family: "SansCondensed150";
  src: url("./authen/AUTHENTICSans-Condensed-150.woff");
}

.Sans60 {
  font-family: "Sans60" !important;
}
.Sans90 {
  font-family: "Sans90" !important;
}
.Sans130 {
  font-family: "Sans130";
}
.Sans150 {
  font-family: "Sans150";
}
.SansCondensed60 {
  font-family: "SansCondensed60";
}
.SansCondensed90 {
  font-family: "SansCondensed90";
}
.SansCondensed130 {
  font-family: "SansCondensed130";
}
.SansCondensed150 {
  font-family: "SansCondensed150";
} */
/* @font-face {
  font-family: "Sans60";
  src: url("./otf/AUTHENTICSans-60.otf");
}
@font-face {
  font-family: "Sans90";
  src: url("./otf/AUTHENTICSans-90.otf");
}
@font-face {
  font-family: "Sans130";
  src: url("./otf/AUTHENTICSans-130.otf");
}
@font-face {
  font-family: "Sans150";
  src: url("./otf/AUTHENTICSans-150.otf");
}
@font-face {
  font-family: "SansCondensed60";
  src: url("./otf/AUTHENTICSans-Condensed-60.otf");
}
@font-face {
  font-family: "SansCondensed90";
  src: url("./otf/AUTHENTICSans-Condensed-90.otf");
}
@font-face {
  font-family: "SansCondensed130";
  src: url("./otf/AUTHENTICSans-Condensed-130.otf");
}
@font-face {
  font-family: "SansCondensed150";
  src: url("./otf/AUTHENTICSans-Condensed-150.otf");
} */

@font-face {
  font-family: "Neue";
  src: url("./Neue\ Montreal/NeueMontreal-Regular.otf");
}
@font-face {
  font-family: "NeueBold";
  src: url("./Neue\ Montreal/NeueMontreal-Bold.otf");
}
@font-face {
  font-family: "NeueItalic";
  src: url("./Neue\ Montreal/NeueMontreal-RegularItalic.otf");
}

/* .Sans60 {
  font-family: "Sans60" !important;
}
.Sans90 {
  font-family: "Sans90" !important;
}
.Sans130 {
  font-family: "Sans130";
}
.Sans150 {
  font-family: "Sans150";
}
.SansCondensed60 {
  font-family: "SansCondensed60";
}
.SansCondensed90 {
  font-family: "SansCondensed90";
}
.SansCondensed130 {
  font-family: "SansCondensed130";
}
.SansCondensed150 {
  font-family: "SansCondensed150";
} */
/* * {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
} */

/* .about h1 {
  font-family: "Sans60";
}
.bold {
  font-family: "Sans130";
} */

.neue {
  font-family: "Neue";
}
.neueBold {
  font-family: "NeueBold";
}
.neueItalic {
  font-family: "NeueItalic";
}
